import React, { Component } from 'react';
import './App.css';

class App extends Component {
  render() {
    return (
      <article>
        <h1 className="gradient-background">Enzo Zuccolotto</h1>
        <p className="huge-text">Hello there <span aria-label="wave hand" role="img">👋👋</span></p>
        <p>I'm software developer and consultant <span aria-label="guy in a computer" role="img">👨‍💻</span><span aria-label="pride flag" role="img">🏳️‍🌈</span> </p>
        <p>Living in <a href="http://goo.gl/mv09fe" target="new">Porto Alegre - Brazil</a> <span aria-label="globe with south america" role="img">🌎</span></p>
        <p>Nowadays I spent most of my time writing code  and helping <a href="https://www.thoughtworks.com">ThoughtWorks's</a> clients to solve business problems.</p>
        <p>And I well versed with <code>JavaScript</code> and <code>Ruby</code>.</p>
        <p>
          You can hear more about here:
          <ul>
            <li><a href="https://github.com/enzoz/" target="new">GitHub</a></li>
            <li><a href="https://twitter.com/_enzoz" target="new">Twitter</a></li>
            <li><a href="https://www.linkedin.com/in/enzoz/" target="new">LinkedIn</a></li>
          </ul>
        </p>
      </article>
    );
  }
}

export default App;
